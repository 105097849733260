const initialState = {
    isLoading: false,
    envID: 0,
    conversationID: null,
    chatHistory: [],
    faqs: [],
    tabId: null,
    themeColor: ''
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ISLOADING":
            return {
                ...state,
                isLoading: action.payload
            };

        case "SET_TABID":
            return {
                ...state,
                tabId: action.payload
            };

        case "SET_THEME_COLOR":
            return {
                ...state,
                themeColor: action.payload
            };

        case "SET_ENV":
            return {
                ...state,
                envID: action.payload
            };

        case "GET_FAQS":
            return {
                ...state,
                faqs: action.payload
            };

        case "RESET_CONVERSATION":
            return {
                ...state,
                conversationID: action.payload,
                chatHistory: []
            };

        case "SEND_MESSAGE":
            const request = action.payload.req;
            const response = action.payload.res;

            if (request.status === 'P') {
                return {
                    ...state,
                    chatHistory: [...state.chatHistory, action.payload.req]
                };
            }
            else {
                const index = state.chatHistory.findIndex(x => x.key === request.key);

                state.chatHistory[index] = {
                    user_prompt: request.user_prompt,
                    timestamp: request.timestamp,
                    bot_response: response,
                    bot_timestamp: request.bot_timestamp,
                    status: request.status
                }

                return {
                    ...state
                };
            }
        // return {
        //     ...state
        // };





        case "GET_CHATHISTORY":
            return {
                ...state,
                conversationHistory: action.payload
            };

        case "GET_CONVERSATION":
            return {
                ...state,
                chatHistory: action.payload,
                isLoading: false
            };

        case "UPDATE_TITLE":
            const index = state.conversationHistory.findIndex(x => x.convoID === action.payload.convoID);

            state.conversationHistory[index] = {
                convoID: action.payload.convoID,
                convoTitle: action.payload.convoTitle,
                lastUpdated: action.payload.lastUpdated,
                isFileUploaded: action.payload.isFileUploaded
            }

            return {
                ...state
            };

        case "DELETE_CONVERSATION":
            const history = state.conversationHistory.filter(x => x.convoID !== action.payload.ConvoID);
            return {
                ...state,
                conversationHistory: history,
                conversationID: 0,
                chatHistory: []
            };

        case "CREATE_CONVERSATION":
            const req = action.payload.req;
            const res = action.payload.res;

            if (req.status === 'P') {
                return {
                    ...state,
                    chatHistory: [req]
                };
            }
            else {
                return {
                    ...state,
                    chatHistory: [res]
                };
            }

        case "SET_CONVERSATIONID":
            return {
                ...state,
                conversationID: action.payload
            };

        case "UPDATE_CONVERSATIONID": {
            const index = state.conversationHistory.findIndex(x => x.convoID === action.payload.key);
            state.conversationHistory[index].convoID = action.payload.conID;
            state.conversationID = action.payload.conID;
            return state;
        }



        case "ADD_CONVEERSATION_HISORY": {
            const req = action.payload;
            return {
                ...state,
                conversationHistory: [req, ...state.conversationHistory],
                conversationID: req.convoID
            };
        }

        case "ISDISABLE_UPLOADICON": {
            const req = action.payload;
            return {
                ...state,
                isFileUploaded: req.isDocumentUploaded,
                fileInfo: req.isDocumentUploaded ? req : null
            };
        }

        case "UPDATE_PAPAERCLIPICON": {
            const index = state.conversationHistory.findIndex(x => x.convoID === action.payload);

            state.conversationHistory[index].isFileUploaded = 1;
            return {
                ...state
            };
        }

        case "REJECT_DOCUMENT": {
            const index = state.conversationHistory.findIndex(x => x.convoID === action.payload);

            state.conversationHistory[index].isFileUploaded = 0;
            state.isFileUploaded = false;
            state.fileInfo = null;
            return {
                ...state
            };
        }

        default:
            return state;
    }
}

export default chatReducer