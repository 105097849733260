import React from 'react'
import { Button } from 'antd';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../config/msal/authConfig';

const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                //console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                // console.log(e);
            });
        }
    }
    return (
        <>
            <Button type="primary" onClick={() => handleLogin("popup")}>Sign in</Button>
        </>
    )
}

export default SignInButton