import React, { useState } from 'react'
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Divider, Radio, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { submitFeedback } from '../redux/actions/chatActions';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';


const ChatgptResponse = ({ currentIndex, inputStr, sources, messageID, setShowHelp, setIsNewTopic, setThumbsDownInfo }) => {

    const { envID } = useSelector(state => state.chat);
    const dispatch = useDispatch();

    const convertToLink = (inputStr) => {
        inputStr = inputStr.replace(/ {2,}/g, " ");
        try {
          let text = inputStr;
          for (let i = 0; i < sources.length; i++) {
            const matchingText = sources[i].name;
            const linkURL = sources[i].link;
    
            // Escape special characters in the sentence to replace
            const escapedSentence = matchingText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    
            const regex = new RegExp(escapedSentence, 'g');
            text = text.replace(regex, `<a style="color:#40A9FF" href="${linkURL}" target="_blank">${matchingText}</a>`);
          }
    
          return text;
        } catch (error) {
          console.log(error);
          return inputStr;
        }
      };

    const onThumbsUp = (msgID) => {
        setShowHelp(null);
        dispatch(
            submitFeedback({
                EnvID: envID,
                MessageID: msgID,
                ThumbDirection: "up"
            })
        )
    }

    const onThumbsDown = (msgID) => {
        setIsNewTopic(true);
        setShowHelp(msgID);
        setThumbsDownInfo(null);
        dispatch(
            submitFeedback({
                EnvID: envID,
                MessageID: msgID,
                ThumbDirection: "down"
            })).then((res) => {
                setThumbsDownInfo(res);
            }).catch((error) => {
                console.error('Error submitFeedback data:', error);
            });
    }

    const convertedText = convertToLink(inputStr);
    
    return (
        <div>
        {inputStr && <ReactMarkdown rehypePlugins={[rehypeRaw]}>{convertedText}</ReactMarkdown>}
        {/* {currentIndex === 0 && <Divider style={{ margin: 10, padding: 0 }} />} */}
            <div style={{ width: '100%', textAlign: 'right', marginTop: 10 }}>
                <Space direction='vertical'>
                    {/* {currentIndex === 0 &&
                        <div style={{ textAlign: 'left' }}>
                            Tip: Don't forget - your next prompt will start a new topic unless you click "Ask another question related to this topic.
                        </div>
                    } */}
                    <Radio.Group size="small">
                        <Space size={[5, 0]}>
                            <Radio.Button value="up" onClick={() => onThumbsUp(messageID)}><LikeOutlined /></Radio.Button>
                            <Radio.Button value="down" onClick={() => onThumbsDown(messageID)}><DislikeOutlined /></Radio.Button>
                        </Space>
                    </Radio.Group>
                </Space>
            </div>
        </div>
    )
}
export default ChatgptResponse