import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Space } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/msal/authConfig";
import { useDispatch } from 'react-redux';
import { refreshToken } from '../redux/actions/authActions';

const SessionTimeout = () => {
    const [seconds, setSeconds] = useState(0);
    const [isSessionTimeout, setIsSessionTimeout] = useState(false);
    const [expiresOn, setExpiresOn] = useState(new Date().getTime() + 30 * 60 * 1000);

    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();

    useEffect(() => {
        const sessionTimer = setInterval(() => {
            const currentTime = new Date().getTime();

            if (expiresOn <= currentTime) {
                setIsSessionTimeout(true);
                setSeconds(300);
                clearInterval(sessionTimer);
            }
        }, 1000);

        return () => clearInterval(sessionTimer);
    }, [expiresOn]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds === 0) {
                clearInterval(timer);
            }
            else {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }

        }, 1000);

        return () => clearInterval(timer);
    }, [seconds])

    const handleSession = useCallback(async () => {
        try {
            setSeconds(null);
            setIsSessionTimeout(false);
            // Silently acquires an access token which is then attached to a request for MS Graph data
            await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                const tokenInfo = {
                    token: response.accessToken
                }
                dispatch(refreshToken(tokenInfo));
                setExpiresOn(new Date().getTime() + 30 * 60 * 1000)
                setIsSessionTimeout(false);
            });
        } catch (error) {
            window.location.reload();
        }
    }, []);

    return (
        <>
            {isSessionTimeout && <div>
                {seconds === 0
                    ? <div style={{ padding: '7px 1px', position: 'absolute', top: '7%', bottom: 0, right: 0, left: 0, zIndex: 2000 }}>
                        <div style={{ height: 55, display: 'flex', alignItems: 'center', backgroundColor: 'antiquewhite', width: '100%' }}>

                            <WarningOutlined style={{ fontSize: 20, color: '#faad14', marginRight: 10, marginLeft: 5 }} />

                            <span>You have been logged out.</span>
                            <Button type='link' onClick={handleSession}>Sign in</Button>
                        </div>
                        <Divider />
                    </div>
                    : <div style={{ padding: '7px 1px', position: 'absolute', top: '7%', bottom: 0, right: 0, left: 0, zIndex: 2000 }}>
                        <Space direction='vertical' size={[0, 0]} style={{ backgroundColor: 'antiquewhite', paddingTop: 5, width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <WarningOutlined style={{ fontSize: 20, color: '#faad14', marginRight: 10, marginLeft: 5 }} />

                                <span style={{ lineHeight: 1.5 }}>You will soon be logged out automatically.<br /> Do you want to stay logged in?</span>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <Button type='link' onClick={handleSession}>Keep me logged in</Button>
                            </div>
                            <Divider style={{ margin: 5 }} />
                        </Space>
                    </div>
                }
            </div>
            }
        </>
    )
}

export default SessionTimeout