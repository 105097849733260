import { Image } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';

export const Support = ({ scrollToHelpRef, thumbsDownInfo }) => {
    const {
        themeColor
    } = useSelector(state => state.chat);     

    const convertToLink = (inputStr) => {
        try {
            var text = inputStr;
            const sources = thumbsDownInfo.sources;
            for (let i = 0; i < sources.length; i++) {
                const matchingText = sources[i].name;
                const linkURL = sources[i].link;

                // Escape special characters in the sentence to replace
                var escapedSentence = matchingText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                const regex = new RegExp(escapedSentence, 'g');
                text = text.replace(regex, '<a href="' + linkURL + '" target="_blank">' + matchingText + '</a>');
            }

            return <div style={{ whiteSpace: 'break-spaces' }}
                dangerouslySetInnerHTML={{ __html: text }}
            />;
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div ref={scrollToHelpRef} style={{ display: 'flex', alignItems: 'flex-start', marginTop: 15 }}>
            <div className='image-bubble' style={{ backgroundColor: themeColor }}><Image
                width={22.5}
                height={18}
                style={{ marginTop: 6 }}
                src='../assets/message-bot-solid.png'
                preview={false}
            /> </div>&nbsp;
            <div className='message' style={{ width: 'auto', maxWidth: '245px', borderRadius: 4 }}>

                <div className='answer'>
                    <p>
                        {convertToLink(thumbsDownInfo?.raw_string)}
                    </p>
                    {/* <p>
                        Tip: {thumbsDownInfo?.tip}
                    </p>
                    <p>
                        If the issue persists, please contact <br />
                        ServiceNow for direct support. <br /><br />
                        <a href='https://dupont.service-now.com/sp?id=bold_support' target='_blank' style={{ textDecoration: 'underline' }}>Connect with ServiceNow support team now</a>
                    </p> */}
                </div>
            </div>
        </div>
    )
}
