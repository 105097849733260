const initialState = {}

const authReducer = (state = initialState, action) => {
    switch (action.type){
        case "USER_PROFILE":
            return action.payload;
        case "REFRESH_TOKEN":
            return {
                ...state,
                token: action.payload.token,
                expiresOn: action.payload.expiresOn
            };
        default:
            return state;
    }
}


export default authReducer