import React from 'react'
import '../styles/loading.css';

const Loading = ({ Text, style }) => {
    return (
        <div className="loading" style={style}> 
            <span />
            <span />
            <span />
        </div>
    )
}

export default Loading