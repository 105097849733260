import { Checkbox, Col, Image, Row } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

const TopicPanel = ({ isNewTopic, setIsNewTopic }) => {
    const {
        themeColor
    } = useSelector(state => state.chat);

    useEffect(() => {
        setIsNewTopic(true);
    }, [])

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 15 }}>
                <div className='image-bubble' style={{ backgroundColor: themeColor }}><Image
                    width={22.5}
                    height={18}
                    style={{ marginTop: 6 }}
                    src='../assets/message-bot-solid.png'
                    preview={false}
                /> </div>&nbsp;
                <div className='message' style={{ width: 'auto', maxWidth: '245px', borderRadius: 4 }}>
                    <div className='answer'>
                        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 5, fontFamily: 'Diodrum-Regular' }}>
                            <Checkbox onChange={() => setIsNewTopic(false)} checked={isNewTopic === false} /> &nbsp;&nbsp;
                            <span>Ask another question <br /> related to this topic</span>
                        </div>
                        <Checkbox onChange={() => setIsNewTopic(true)} checked={isNewTopic === true} style={{ fontFamily: 'Diodrum-Regular', color:'#313131', fontSize:15}}>
                            <span>Start a new topic</span>
                        </Checkbox>
                    </div>
                </div>
            </div>

            {isNewTopic === false &&
                <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 15 }}>
                    <div className='image-bubble' style={{ backgroundColor: themeColor }}><Image
                        width={22.5}
                        height={18}
                        style={{ marginTop: 6 }}
                        src='../assets/message-bot-solid.png'
                        preview={false}
                    /> </div>&nbsp;
                    <div className='message' style={{ width: 'auto', maxWidth: '245px', borderRadius: 4 }}>
                        <div className='answer'>
                            OK. Enter your next question <br /> related to this topic.
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TopicPanel