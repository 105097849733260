
export const Auth_TYPES = {
    USER_PROFILE: 'USER_PROFILE',
    REFRESH_TOKEN:'REFRESH_TOKEN'
} 

export const setUserProfile = (data) => async (dispatch) => { 
    try { 
        dispatch({ 
            type: Auth_TYPES.USER_PROFILE, 
            payload: data
        })  
        
    } catch (err) {
         
    }
}

export const refreshToken = (data)=> async(dispatch) => {
    try {
        dispatch({ 
            type: Auth_TYPES.REFRESH_TOKEN, 
            payload: data
        })
    } catch (error) {
        
    }
}