import moment from "moment";
import { axiosClient } from "../../config/axiosConfig"

export const Chat_TYPES = {
    SET_ISLOADING: 'SET_ISLOADING',
    SET_ENV: 'SET_ENV',
    SET_CONVERSATIONID: 'SET_CONVERSATIONID',
    SET_TABID: 'SET_TABID',
    GET_FAQS: 'GET_FAQS',
    RESET_CONVERSATION: 'RESET_CONVERSATION',
    SET_THEME_COLOR: 'SET_THEME_COLOR',

    GET_CHATHISTORY: 'GET_CHATHISTORY',
    GET_CONVERSATION: 'GET_CONVERSATION',
    SET_CONVERSATIONID: 'SET_CONVERSATIONID',
    CREATE_CONVERSATION: 'CREATE_CONVERSATION',
    DELETE_CONVERSATION: 'DELETE_CONVERSATION',
    CLEAR_ALL_CONVERSATIONS: 'CLEAR_ALL_CONVERSATIONS',
    UPDATE_TITLE: 'UPDATE_TITLE',
    UPDATE_CONVERSATIONID: 'UPDATE_CONVERSATIONID',
    SEND_MESSAGE: 'SEND_MESSAGE',
    ADD_CONVEERSATION_HISORY: 'ADD_CONVEERSATION_HISORY',
    ISDISABLE_UPLOADICON: 'ISDISABLE_UPLOADICON',
    UPDATE_PAPAERCLIPICON: 'UPDATE_PAPAERCLIPICON',
    REJECT_DOCUMENT: 'REJECT_DOCUMENT'
}

export const setIsLoading = (data) => async (dispatch) => {
    dispatch({
        type: Chat_TYPES.SET_ISLOADING,
        payload: data
    })
}

export const setTabId = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.SET_TABID,
            payload: data
        })
    } catch (error) {

    }
}

export const setThemeColor = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.SET_THEME_COLOR,
            payload: data
        })
    } catch (error) {

    }
}

export const setEnv = (data) => async (dispatch) => {
    const response = await axiosClient.post('/api/chat/set_env/', data);
    dispatch({
        type: Chat_TYPES.SET_ENV,
        payload: response
    })
}

export const getFAQList = (data) => async (dispatch) => {
    try {
        const response = await axiosClient.post('/api/chat/get_faq_collection/', data);

        dispatch({
            type: Chat_TYPES.GET_FAQS,
            payload: response
        })

    } catch (err) {

    }
}

export const createConversationID = (data) => async (dispatch) => {
    try {
        const response = await axiosClient.post('/api/chat/create_convo/', data);

        dispatch({
            type: Chat_TYPES.SET_ENV,
            payload: response
        })

    } catch (err) {

    }
}

export const resetConversation = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.RESET_CONVERSATION,
            payload: null
        })
    } catch (err) {

    }
}

export const submitFeedback = (data) => async (dispatch) => {
    try {
        return await axiosClient.post('/api/chat/send_thumb/', data);
    } catch (err) {

    }
}

export const getFAQInfo = (data) => async (dispatch) => {
    try {
        // Replace conversatoinID
        await dispatch({
            type: Chat_TYPES.SET_CONVERSATIONID,
            payload: 0
        })

        dispatch({
            type: Chat_TYPES.CREATE_CONVERSATION,
            payload: { req: data, res: '' }
        })

        // Get conversationID

        if (data.ConvoID === null) {
            const conID = await axiosClient.post('/api/chat/create_convo/', {
                EnvID: data.EnvID
            });

            data.ConvoID = conID;

            // Replace conversatoinID
            await dispatch({
                type: Chat_TYPES.SET_CONVERSATIONID,
                payload: conID
            })
        }

        try {
            // Get Answer by passing Conversation, Question and UserID
            const response = await axiosClient.post('/api/chat/get_faq/', {
                EnvID: data.EnvID,
                ConvoID: data.ConvoID,
                FaqID: data.FaqID
            }
            );

            data.status = '';

            const answer = {
                user_prompt: data.user_prompt,
                timestamp: data.timestamp,
                bot_response: response,
                bot_timestamp: moment()
            }

            dispatch({
                type: Chat_TYPES.CREATE_CONVERSATION,
                payload: { req: data, res: answer }
            })
        }
        catch (err) {
            data.status = 'E';
            const answer = {
                user_prompt: data.user_prompt,
                timestamp: data.timestamp,
                bot_response: '',
                bot_timestamp: moment(),
                status: 'E'
            }

            dispatch({
                type: Chat_TYPES.CREATE_CONVERSATION,
                payload: {
                    req: data,
                    res: answer
                }
            })
        }

    } catch (err) {

    }

}

export const createConversation = (data) => async (dispatch) => {
    try {
        // Replace conversatoinID
        await dispatch({
            type: Chat_TYPES.SET_CONVERSATIONID,
            payload: 0
        })

        dispatch({
            type: Chat_TYPES.CREATE_CONVERSATION,
            payload: { req: data, res: '' }
        })

        // Get conversationID
        const conID = await axiosClient.post('/api/chat/create_convo/', {
            EnvID: data.EnvID
        });

        // Replace conversatoinID
        await dispatch({
            type: Chat_TYPES.SET_CONVERSATIONID,
            payload: conID
        })

        try {
            // Get Answer by passing Conversation, Question and UserID
            const response = await axiosClient.post('/api/chat/next_msg/', {
                EnvID: data.EnvID,
                ConvoID: conID,
                UserPrompt: data.user_prompt,
                IsNewTopic: true
            }
            );

            data.status = '';

            const answer = {
                user_prompt: data.user_prompt,
                timestamp: data.timestamp,
                bot_response: response,
                bot_timestamp: moment()
            }

            dispatch({
                type: Chat_TYPES.CREATE_CONVERSATION,
                payload: { req: data, res: answer }
            })
        }
        catch (err) {
            data.status = 'E';
            const answer = {
                user_prompt: data.user_prompt,
                timestamp: data.timestamp,
                bot_response: '',
                bot_timestamp: moment(),
                status: 'E'
            }

            dispatch({
                type: Chat_TYPES.CREATE_CONVERSATION,
                payload: {
                    req: data,
                    res: answer
                }
            })
        }

    } catch (err) {

    }
}

export const sendMessage = (data) => async (dispatch) => {
    dispatch({
        type: Chat_TYPES.SEND_MESSAGE,
        payload: { req: data, res: '' }
    })

    try {
        const response = await axiosClient.post('/api/chat/next_msg/', {
            EnvID: data.EnvID,
            ConvoID: data.ConvoID,
            UserPrompt: data.user_prompt,
            IsNewTopic: data.isNewTopic
        }
        );

        data.status = '';

        dispatch({
            type: Chat_TYPES.SEND_MESSAGE,
            payload: { req: data, res: response }
        })
    } catch (err) {
        data.status = 'E';
        dispatch({
            type: Chat_TYPES.SEND_MESSAGE,
            payload: {
                req: data,
                res: ''
            }
        })
    }

    // try {
    //     if (data.chatHistoryLength === 2) {
    //         const title = await axiosClient.post('/api/chat/model-title/', {
    //             ConvoID: data.ConvoID,
    //             UserID: data.UserID
    //         }
    //         );

    //         var newTitle = {
    //             convoID: data.ConvoID,
    //             convoTitle: title,
    //             lastUpdated: moment()
    //         }
    //         dispatch({
    //             type: Chat_TYPES.UPDATE_TITLE,
    //             payload: newTitle
    //         })
    //     }
    // } catch (err) {

    // }
}