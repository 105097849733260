import { Typography } from 'antd'
import React from 'react'

const { Title, Text } = Typography;

const PageNotFound = () => {
    return (
        <div style={{ margin: '35% 7%' }}>
            <div>
                <Title level={4}>404 - Page Not Found</Title>
                <Text>Oops! The page you are looking for does not exist.</Text>
            </div>
        </div>
    )
}

export default PageNotFound