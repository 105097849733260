import React, { useEffect, useRef, useState } from 'react'
import { ReloadOutlined, SendOutlined } from '@ant-design/icons/lib/icons';
import { Button, Divider, Form, Image, Input, Space, Tag, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Loading from './Loading';
import CopyToClipboard from './CopyToClipboard';
import ChatgptResponse from './ChatgptResponse';
import moment from 'moment';
import { createConversation, sendMessage, getFAQList, getFAQInfo, resetConversation, setTabId } from '../redux/actions/chatActions';
import { v4 as uuid } from 'uuid';
import { Support } from './Support';
import TopicPanel from './TopicPanel';

const { TextArea } = Input;

const SmartChat = ({ pageId }) => {
    const [showHelp, setShowHelp] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const [isNewTopic, setIsNewTopic] = useState(true);
    const [thumbsDownInfo, setThumbsDownInfo] = useState(null);
    const [isFAQLoading, setIsFAQLoading] = useState(false);

    const [form] = Form.useForm();
    const inputRef = useRef(null);
    const scrollToHelpRef = useRef(null);

    const {
        user
    } = useSelector(state => state.auth);

    const {
        isLoading,
        envID,
        chatHistory,
        conversationID,
        faqs,
        tabId,
        themeColor
    } = useSelector(state => state.chat);

    const dispatch = useDispatch();

    useEffect(() => {
        const getFAQS = () => {
            setIsFAQLoading(true);
            dispatch(getFAQList({
                EnvID: envID
            })).then(res => setIsFAQLoading(false));
        }

        if (envID > 0) {
            getFAQS();
        }
    }, [envID]);

    useEffect(() => {
        const scrollToBottom = () => {
            //   if (conversationID !== 0) {
            let scroll_to_bottom = document.getElementById('scroll-to-bottom');
            //scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
            scroll_to_bottom.scrollTo({
                top: scroll_to_bottom.scrollHeight,
                behavior: 'smooth'
            });
            //}
        }
        scrollToBottom();
        inputRef.current && inputRef.current.focus()
    }, [chatHistory, isNewTopic]);

    useEffect(() => {
        // Scroll to the inner div when the component mounts
        if (scrollToHelpRef.current) {
            scrollToHelpRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [showHelp]);

    const transformDate = date => {
        return moment.utc(date).local().format('MM/DD/YYYY, hh:mm A')
    }

    const currentTabName = () => {
        const tabNames = {
            '1': 'IT',
            '2': 'Procurement',
            '3': 'OpEx',
        };

        return tabNames[tabId] || '';
    };

    const onFinish = () => {
        setShowHelp(false);
        const message = form.getFieldValue('message');

        if (message === undefined) {
            alert('Please enter user prompt');
            return false;
        }


        if (conversationID === null) {
            const uniqueID = uuid();

            let req = {
                user_prompt: message,
                EnvID: envID,
                ConvoID: 0,
                userID: user.email,
                timestamp: moment(),
                bot_response: '',
                bot_timestamp: moment(),
                status: 'P',
                key: uniqueID
            }
            dispatch(createConversation(req))
        }

        if (conversationID > 0) {
            let req = {
                user_prompt: message,
                EnvID: envID,
                ConvoID: conversationID,
                UserID: user.email,
                timestamp: moment(),
                bot_response: '',
                bot_timestamp: moment(),
                status: 'P',
                chatHistoryLength: chatHistory.length,
                key: uuid(),
                isNewTopic: isNewTopic
            }

            dispatch(sendMessage(req)).then((res) => {
            }).catch((error) => {
                // console.log("An error occurred:", error);
            });
        }

        form.resetFields();
    }

    const getFAQ = (faq) => {
        setShowHelp(false);
        let req = {
            user_prompt: faq.question,
            EnvID: envID,
            ConvoID: conversationID,
            FaqID: faq.faq_id,
            userID: user.email,
            timestamp: moment(),
            bot_response: '',
            bot_timestamp: moment(),
            status: 'P',
            key: uuid()
        }

        dispatch(getFAQInfo(req))
    }

    const transformNewDate = date => {
        if (moment.utc().local().diff(date, 'days') > 1) {
            return moment.utc(date).local().fromNow(); // '2 days ago' etc.
        }

        return moment.utc(date).local().calendar().split(' ')[0] + ' ' + moment.utc(date).local().format("h:mm A"); // 'Today', 'yesterday', 'tomorrow'
    }

    const onTabClick = (id) => {
        if (tabId == id)
            return false;

        dispatch(setTabId(id));
        dispatch(resetConversation(null));
    }

    return (
        <>
            <div className="page-tabs-div" style={{ borderBottom: `2px solid ${themeColor}` }}>
                <ul className="pageTabs">
                    {pageId == 1 && <li className="pageTab activePageTab" style={{ backgroundColor: themeColor }} onClick={() => onTabClick(1)} >IT</li>}
                    {pageId == 2 && <li className="pageTab activePageTab" style={{ backgroundColor: themeColor }} onClick={() => onTabClick(2)}>Procurement</li>}
                    {pageId == 3 && <li className="pageTab activePageTab" style={{ backgroundColor: themeColor }} onClick={() => onTabClick(3)}>OpEx</li>}
                    {(pageId == 0 || !pageId || pageId > 3) && <>
                        <li className="pageTab activePageTab" style={{ opacity: tabId == 1 ? 1 : 0.4 }} onClick={() => onTabClick(1)}>IT</li>
                        <li className="pageTab procTab" style={{ opacity: tabId == 2 ? 1 : 0.4 }} onClick={() => onTabClick(2)}>Procurement</li>
                        <li className="pageTab opexTab" style={{ opacity: tabId == 3 ? 1 : 0.4 }} onClick={() => onTabClick(3)}>OpEx</li>
                    </>
                    }
                </ul>
            </div>
            <div className='chat-messages scrollview' id='scroll-to-bottom'>

                <div className='chatgpt'>
                    <Space direction='vertical' style={{ width: '100%' }}>
                        {/* {isLoading && <div style={{ marginLeft: '15%' }}><LoadingSpinner /></div>} */}
                        {chatHistory.length > 0 && chatHistory.map((chat, i) => (
                            chat.user_prompt !== '' && <>
                                <React.Fragment key={`chatHistory_${i}`}>
                                    {chat?.bot_response?.is_new_convo && <Divider orientation={'center'} style={{ color: themeColor, borderColor: themeColor, fontSize: 12 }}>
                                        New conversation<br /> {transformNewDate(chat.bot_timestamp)}
                                    </Divider>
                                    }

                                    <div key={`question${i}`} className='question' style={{ float: 'right' }}>
                                        <div className='message' style={{ backgroundColor: '#E2EDF6', width: 'auto', paddingTop: 10, borderRadius: 4 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 24 }}>
                                                <span style={{ fontSize: 15, color: '#BFBFBF', color: '#708090' }}>{transformDate(chat.timestamp)}</span>
                                                {/* <CopyToClipboard text={chat.user_prompt} name="Copy" /> */}
                                            </div>
                                            <div className='answer' style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{chat.user_prompt}</div>
                                        </div>
                                    </div>
                                    <div key={`answer${i}`} className='answer'>
                                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <div className='image-bubble' style={{ backgroundColor: themeColor }}><Image
                                                width={22.5}
                                                height={18}
                                                style={{ marginTop: 6 }}
                                                src='../assets/message-bot-solid.png'
                                                preview={false}
                                            /> </div>&nbsp;
                                            <div className='message' style={{ width: 'auto', maxWidth: '245px', borderRadius: 4 }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <Space>
                                                            <span style={{ fontSize: 15, color: '#708090' }}>{transformDate(chat.bot_timestamp)}</span>
                                                        </Space>
                                                    </div>

                                                    <CopyToClipboard key={`CopyToClipboard_${i}`} text={chat.bot_response && chat.bot_response.raw_string} sources={chat.bot_response && chat.bot_response.sources} name="Copy" />
                                                </div>
                                                <div className='answer'>
                                                    {
                                                        chat.status === 'P' && <Loading style={{ width: 100, height: 30, margin: '5px 0px' }} />
                                                    }

                                                    {
                                                        chat.status !== 'E' && chat.status !== 'P' && <ChatgptResponse key={`chatgptResponse_${i}`} currentIndex={i} setShowHelp={setShowHelp} inputStr={chat.bot_response.raw_string} sources={chat.bot_response.sources} messageID={chat.bot_response.message_id} setIsNewTopic={setIsNewTopic} setThumbsDownInfo={setThumbsDownInfo} />
                                                    }

                                                    {
                                                        chat.status === 'E' && <div style={{ color: 'red' }}>
                                                            Oops! Something went wrong. The task could not be performed due to system error. Please try again, start a new conversation, or rephrase your message. If the problem persists, contact our <a href="mailto:DL-R-GLOBAL-genaichat-support@dupont.com?subject=DuPont GenAI Chat Support&body=" style={{ color: '#009cde' }}>support team</a>.
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {chat?.bot_response?.message_id === showHelp && <Support scrollToHelpRef={scrollToHelpRef} thumbsDownInfo={thumbsDownInfo} />
                                        }

                                        {(chat.status !== 'P' && i === chatHistory.length - 1) && <TopicPanel isNewTopic={isNewTopic} setIsNewTopic={setIsNewTopic} />}
                                    </div>

                                </React.Fragment>
                            </>
                        ))
                        }
                    </Space>
                </div>



                {conversationID === null && <>

                    <Space size={[7, 0]} style={{ alignItems: 'flex-start', display: 'flex' }}>
                        <div className='image-bubble' style={{ backgroundColor: themeColor }}><Image
                            width={22.5}
                            height={18}
                            style={{ marginTop: 6 }}
                            src='../assets/message-bot-solid.png'
                            preview={false}
                        /> </div>

                        <div className="message received">
                            Welcome to {currentTabName()} Knowledge Finder! Whether you need assistance with {currentTabName()} resources or support, I’ll help you find it!
                        </div>
                    </Space>

                    <br />
                    {(tabId == 1 || tabId == 2 )&&
                        <div style={{ marginLeft: '12%' }}>
                            <div style={{ fontSize: 15 }}>
                                <strong>Suggestions</strong> (tap to select)
                            </div>

                            <div style={{ marginTop: 10 }}>
                                {isFAQLoading
                                    ? <Loading style={{ width: 100, height: 30, margin: '20px' }} />
                                    : <Space direction='vertical' size={[10, 10]}>
                                        {
                                            faqs.length > 0 && faqs.map((faq, i) => (
                                                <Tag
                                                    key={`faq${i}`}
                                                    onClick={() => getFAQ(faq)}
                                                    style={{ cursor: 'pointer', border: `1px solid ${themeColor}` }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor = themeColor;
                                                        e.target.style.color = 'white';
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor = 'white';
                                                        e.target.style.color = '#313131';
                                                    }}
                                                >
                                                    {faq.question}
                                                </Tag>
                                            ))
                                        }
                                    </Space>
                                }
                            </div>
                        </div>
                    }
                </>
                }

            </div>

            <div className="chat-input">
                {/* <Input placeholder='Type a message...' bordered={false} /> */}
                <Form
                    form={form}
                    onFinish={onFinish}
                    onKeyPress={(e) => {
                        if (!e.shiftKey && e.key === "Enter") {
                            form.submit();
                            //setIsExpand(false);
                        }
                    }
                    }
                    style={{ width: '100%' }}
                >
                    <Form.Item
                        name='message'
                        style={{ width: '95%' }}
                    >
                        <TextArea
                            // placeholder='Type a message...'
                            placeholder={isNewTopic ? 'Start a new topic' : 'Enter next question related to this topic'}
                            //size="large"
                            autoSize={{
                                minRows: 1,
                                maxRows: 2,
                            }}
                            bordered={false}
                            maxLength={1000}
                            style={{ padding: '4px', fontSize: '14px', fontFamily: 'Diodrum-Regular' }}
                            onChange={(e) => form.setFieldsValue({ message: e.target.value.trimStart() })}
                            ref={inputRef}
                        />

                        {/* <Input ref={inputRef} placeholder='Type a message...' bordered={false} onChange={(e) => form.setFieldsValue({ message: e.target.value.trimStart() })} /> */}
                    </Form.Item>
                </Form>
                {/* <input type="text" placeholder="Type your message..." /> */}

                <Form.Item>
                    <Button type='link'
                        className="button"
                        // onClick={onFinish}
                        onClick={() => form.submit()}
                    >
                        <SendOutlined
                        />
                    </Button>
                </Form.Item>
            </div>

            <div className="chat-feedback">
                <Tooltip title='Clear and start a new session'>
                    <Button
                        type='link'
                        onClick={() => dispatch(resetConversation(null))}
                        disabled={chatHistory.length === 0}
                        style={{ padding: 0, color: chatHistory.length > 0 ? '#313131' : '' }}
                    >
                        <ReloadOutlined style={{ fontSize: 16 }} />
                    </Button>
                </Tooltip>
            </div>


            {showModal && <>
                {/* Backgrounnd mast */}
                <div style={{ position: 'absolute', top: 10, bottom: 0, right: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.45)' }}></div>

                {/* Modal Content */}
                <div style={{ display: showModal ? '' : 'none', position: 'absolute', top: '10%', backgroundColor: '#fff', borderRadius: 29, margin: 10 }}>
                    <div style={{ padding: '10px 15px 0px 15px' }}>
                        <h4 style={{ color: themeColor }}>
                            Welcome to {currentTabName()} Knowledge Finder!
                        </h4>

                        <p>
                            Click the 'About' link at the top of the chat window for more information on the resources available in this Knowledge Finder. You can also find details on release notes, bug fixes, and future upgrades!
                        </p>
                    </div>
                    <Divider style={{ marginTop: 5 }} />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => setShowModal(false)}
                            style={{ backgroundColor: themeColor, color: '#fff', margin: 10 }}
                        >
                            OK
                        </Button>
                    </div>

                </div>
            </>
            }
        </>
    )
}

export default SmartChat