import React from 'react'
import SignInButton from './SignInButton'

const Login = () => {
    return (
        <div style={{top: '50%', textAlign: 'center', width: '100%' }}>
            <div style={{ lineHeight: 2 }}>
                <p>Please click on Sign in button to enter the chat room.</p>
                <div style={{ margin: 'auto' }}>
                    <SignInButton />
                </div>
            </div>
        </div>
    )
}

export default Login