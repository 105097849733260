import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react'

const CopyToClipboard = ({ text, name, sources }) => {
    const [isCopied, setIsCopied] = useState(false);
    useEffect(() => {
        let timeId = null;
        if (isCopied) {
            timeId = setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        }
        else {
            clearTimeout(timeId)
        }
    }, [isCopied]);


    const copyToClipboard = async(text) => {
        if (sources.length > 0) {
            const transformedString = await convertToLink(text);
            navigator.clipboard.writeText(transformedString);
        }
        else {
            navigator.clipboard.writeText(text);
        }
        setIsCopied(true);
    }

    const convertToLink = async(inputStr) => {
        try {
            var raw_string = inputStr;
            for (let i = 0; i < sources.length; i++) {
                console.log(sources[i].name)
                const matchingText = sources[i].name;
                const linkURL = sources[i].link;

                // Escape special characters in the sentence to replace
                var escapedSentence = matchingText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                const regex = new RegExp(escapedSentence, 'g');
                raw_string = raw_string.replace(regex, `(${linkURL})`);
            }

            return raw_string;
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div onClick={() => copyToClipboard(text)}>
            {text.length > 0 &&
                <Space size={[2, 0]} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    {!isCopied && <>
                        <CopyOutlined />
                        <span style={{ fontSize: 12 }}>{name}</span>
                    </>
                    }
                    {isCopied && <>
                        <CheckOutlined />
                        <span style={{ fontSize: 12 }}>Copied!</span>
                    </>
                    }
                </Space>
            }
        </div>
    )
}

export default CopyToClipboard